import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OverlayTrigger, Popover, Card } from 'react-bootstrap';
import { FaInfoCircle, FaExpandArrowsAlt } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';

AdItem.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    preview: PropTypes.shape({
      previewUrl: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  }),
  data: PropTypes.shape({
    impressions: PropTypes.number,
    clicks: PropTypes.number,
    custom: PropTypes.number
  }),
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const CardOverlay = styled(Card.ImgOverlay)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1.4em;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: inherit;
  box-sizing: border;
`;

const DataContainer = styled.div`
  font-size: 80%;
  display: flex;
  justify-content: space-between;
`;

function AdItem(props) {
  const { id, name, preview, deleted } = props.ad;
  const [isOpen, setIsOpen] = useState(false);
  const popOver = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{name}</Popover.Title>
      <Popover.Content>
        <div>ID: {id}</div>
        {deleted && <div className="text-danger">Ad is deleted</div>}
      </Popover.Content>
    </Popover>
  );
  return (
    <Card
      border={props.selected ? 'primary' : 'default'}
      bg="default"
      className="position-relative mr-1 mb-1 p-1 h-100"
      style={{ borderWidth: 4 }}
    >
      <div className="h-100 d-flex justify-content-center align-items-center" onClick={props.onClick}>
        {!preview.previewUrl && <div>No preview</div>}
        {preview.previewUrl && (
          <Card.Img
            style={{ opacity: deleted ? 0.7 : 1.0 }}
            className={`p-0 ${props.selected ? 'bg-primary' : 'bg-default'}`}
            src={preview.previewUrl}
          />
        )}
      </div>
      <CardOverlay className="m-1">
        <FaExpandArrowsAlt
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <OverlayTrigger placement="bottom" overlay={popOver}>
          <FaInfoCircle />
        </OverlayTrigger>
        {isOpen && (
          <Lightbox
            type="image"
            mainSrc={preview.previewUrl}
            onCloseRequest={() => {
              setIsOpen(false);
            }}
            enableZoom={false}
          />
        )}
      </CardOverlay>
      <Card.Footer className="p-1">
        <DataContainer>
          <div>Impressions</div>
          <div>
            <strong>{props?.data?.impressions ?? 0}</strong>
          </div>
        </DataContainer>
        <DataContainer>
          <div>Clicks</div>
          <div>
            <strong>{props?.data?.clicks ?? 0}</strong>
          </div>
        </DataContainer>
        <DataContainer>
          <div>Events</div>
          <div>
            <strong>{props?.data?.custom ?? 0}</strong>
          </div>
        </DataContainer>
        <DataContainer>
          <div>Engagement</div>
          <div>
            <strong>{props?.data?.engagement?.toFixed(2) ?? 0} %</strong>
          </div>
        </DataContainer>
      </Card.Footer>
    </Card>
  );
}

export default AdItem;
