import moment from 'moment-timezone';

export function createDateRange(from, to, options = {}) {
  const { timezone, resolution } = options;
  const dateArray = [];
  const timeResolution = resolution || 'days';
  const tz = timezone || 'EET';
  let currentDate = moment.tz(from, tz).startOf(timeResolution);
  let stopDate = moment.tz(to, tz).endOf(timeResolution);

  while (currentDate <= stopDate) {
    dateArray.push(currentDate.toISOString());
    currentDate.add(1, timeResolution);
  }
  return dateArray;
}

export function trimString(string, length) {
  if (string === undefined || length === undefined || isNaN(parseInt(length))) {
    throw new Error('Invalid parameters: string and length is required');
  }

  if (string.length <= length) {
    return string;
  }

  return string.substring(0, length) + '...';
}

export function getCurrentHash() {
  const queryHash = typeof window !== 'undefined' ? window.location.hash : '';
  // strip hash char and leading slashes
  const hash = queryHash.substring(1).replace(/^\/+/, '');
  return hash;
}

export function parseHash() {
  const hash = getCurrentHash();
  const [uuid, query = ''] = hash.split('?');
  const params = query.split('&').reduce((m, param) => {
    const [key, value] = param.split('=');
    if (!m[key]) {
      m[key] = value;
    } else {
      if (Array.isArray(m[key])) {
        m[key].push(value);
      } else {
        m[key] = [m[key], value];
      }
    }
    return m;
  }, {});
  return {
    uuid,
    query,
    params
  };
}

export function paginationNumbers(count, current, items = 9) {
  const maxItems = Math.max(items, 3); // we need at least 3 items
  if (maxItems >= count) {
    return [...Array(count).keys()];
  }
  const result = []; //always include first page
  const delta = Math.floor(maxItems / 2);
  // we need extra item after active when we can't have same amount on both sides
  const extra = maxItems % 2 === 0 ? 1 : 0;
  let next = Math.min(Math.max(0, current - delta), count - maxItems);
  let last = Math.min(
    Math.max(current + delta - extra, maxItems - 1),
    count - 1
  );
  for (let i = next; i <= last; i++) {
    result.push(i);
  }

  // replace first/last with ellipsis if necessary
  if (result[0] !== 0) {
    result[0] = '...';
  }

  if (result[result.length - 1] !== count - 1) {
    result[result.length - 1] = '...';
  }
  return result;
}

export function getFirstAvailableResolution(resolution, availableResolutions) {
  if (
    !Array.isArray(availableResolutions) ||
    availableResolutions.length === 0
  ) {
    return;
  }

  let idx = availableResolutions.findIndex(r => r.value === resolution);
  if (idx >= 0 && availableResolutions[idx].disabled) {
    idx--;
    while (idx >= 0) {
      if (!availableResolutions[idx].disabled) {
        break;
      }
      idx--;
    }

    if (idx < 0) {
      // no resolutions found, return original
      return resolution;
    }

    return availableResolutions[idx].value;
  }

  return resolution;
}
