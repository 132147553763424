import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap';

TimeResolutionPicker.propTypes = {
  value: PropTypes.oneOf(['hours', 'days', 'weeks', 'months']).isRequired,
  onChange: PropTypes.func.isRequired,
  resolutions: PropTypes.array.isRequired
};

function TimeResolutionPicker(props) {
  const { value, onChange, resolutions = [], allEnabled = false } = props;
  const buttonVariant = 'outline-secondary';
  const buttonSize = 'sm';

  return (
    <div>
      <ButtonToolbar>
        <ToggleButtonGroup
          type="radio"
          name="resolution"
          value={value}
          onChange={resolution => {
            onChange(resolution);
          }}
        >
          {resolutions.map(resolution => {
            return (
              <ToggleButton
                key={resolution.value}
                variant={buttonVariant}
                size={buttonSize}
                value={resolution.value}
                disabled={!allEnabled && resolution.disabled}
              >
                {resolution.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
  );
}

export default TimeResolutionPicker;
