import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Button, Collapse, Container } from 'react-bootstrap';

import AdItem from './AdItem';
import SectionCard from 'common/SectionCard';

import useDebounce from '../../utils/useDebounce';
import { toggleAd, selectAds, toggleOpen } from './adPickerSlice';
import { adsSelector, adTotalDataSelector } from 'app/selectors';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const DEFAULT_DELAY = 500; // default debounce value

const AdPicker = props => {
  const { onSelect = () => {}, loading } = props;
  const { selectedAds, isOpen } = useSelector(state => state.adPicker);
  const ads = useSelector(adsSelector);
  const adData = useSelector(adTotalDataSelector);
  const dispatch = useDispatch();

  const debouncedSelected = useDebounce(
    selectedAds,
    props.selectDelay || DEFAULT_DELAY
  );

  const clickHandler = useCallback(
    uuid => {
      dispatch(toggleAd(uuid));
    },
    [dispatch]
  );

  useEffect(() => {
    const selectedKeys = Object.keys(debouncedSelected).filter(id => {
      return debouncedSelected[id] === true;
    });
    onSelect(selectedKeys);
  }, [debouncedSelected, onSelect]);

  useEffect(() => {
    onSelect([]); // ads changed, deselect all old ads
    dispatch(selectAds(ads));
  }, [dispatch, ads, onSelect]);

  const adItems = ads.map(ad => {
    return (
      <Col className="mb-2" key={ad.id}>
        <AdItem
          ad={ad}
          selected={selectedAds[ad.uuid]}
          data={adData[ad.uuid]}
          onClick={() => {
            clickHandler(ad.uuid);
          }}
        />
      </Col>
    );
  });

  const handleOpenToggle = useCallback(() => {
    dispatch(toggleOpen());
  }, [dispatch]);

  const toolbar = (
    <Button
      size="sm"
      style={{ minWidth: 100 }}
      variant="outline-secondary"
      active={isOpen}
    >
      {isOpen ? (
        <span>
          Hide ads <FaChevronDown />
        </span>
      ) : (
        <span>
          Show ads <FaChevronUp />
        </span>
      )}
    </Button>
  );

  const selectedAmount = Object.entries(selectedAds).reduce(
    (count, [key, value]) => {
      if (value === true) {
        count++;
      }
      return count;
    },
    0
  );
  const subtitle = ads && (
    <small>
      <em>
        {selectedAmount} of {ads.length} ads selected
      </em>
    </small>
  );
  return (
    <SectionCard
      onClick={handleOpenToggle}
      title="Ads"
      subtitle={subtitle}
      toolbar={toolbar}
      loading={loading}
    >
      <Collapse in={isOpen}>
        <Container fluid className="px-0">
          <Row className="d-flex flex-wrap justify-content-start row-cols-2 row-cols-md-3 row-cols-lg-4">
            {adItems}
          </Row>
        </Container>
      </Collapse>
    </SectionCard>
  );
};

AdPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectDelay: PropTypes.number
};

export default React.memo(AdPicker);
