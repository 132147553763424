import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import SectionCard from 'common/SectionCard';
import PieChart from 'common/PieChart';
import DataTable from 'common/DataTable';

ClickDetails.propTypes = {
  data: PropTypes.array.isRequired
};

function ClickDetails(props) {
  const { data, loading } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: 'URL',
        accessor: 'name',
        Cell: ({ row: { original } }) => {
          return (
            <a
              href={original.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-right"
            >
              {original.name}
            </a>
          );
        },
        Footer: () => <strong>Total</strong>
      },
      {
        Header: 'Clicks',
        accessor: 'clicks',
        className: 'text-right',
        Footer: info => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.clicks + sum, 0),
            [info.rows]
          );

          return (
            <div className="text-right">
              <strong>{total}</strong>
            </div>
          );
        }
      }
    ],
    []
  );
  const tableData = React.useMemo(() => {
    return data;
  }, [data]);

  const exportOptions = {
    filename: 'url-data.csv',
    getData: (columns, rows) => {
      const headers = ['url', 'clicks'];
      const data = rows.map(row => {
        return [row.original.url, row.values.clicks];
      });
      return [headers, ...data];
    }
  };

  return (
    <SectionCard title="URLs and clicks" loading={loading}>
      <Row>
        <Col md={12} lg={6}>
          <PieChart data={data} x="name" y="clicks" />
        </Col>
        <Col>
          <DataTable
            columns={columns}
            data={tableData}
            exportOptions={exportOptions}
          />
        </Col>
      </Row>
    </SectionCard>
  );
}

export default ClickDetails;
