import axios from 'axios';
import config from 'config';
axios.defaults.timeout = 15000;

const { API_URL: baseUrl, TIMEZONE: timezone } = config;

const getFetchError = error => {
  let message = 'Unknown error';
  if (error.response) {
    const { data } = error.response;
    if (data.error) {
      message = data.error;
    }
  } else if (error.request) {
    message = 'Request failed';
  } else {
    message = error.message;
  }
  return message;
};

const _fetchCampaign = async (uuid, query = '') => {
  const response = await axios.get(`${baseUrl}/${uuid}?${query}`);
  return response.data;
};

export const fetchCampaign = async (uuid, query) => {
  try {
    const { campaign } = await _fetchCampaign(uuid, query);
    if (!campaign) {
      throw new Error('Campaign not found');
    }
    const ads = campaign.ads || [];
    const apps = campaign.apps || {};
    delete campaign.ads;
    delete campaign.apps;

    return { campaign, ads, apps };
  } catch (error) {
    let message = getFetchError(error);
    throw new Error('Failed to fetch campaign: ' + message);
  }
};

export const fetchAnalyticsData = async (
  uuid,
  filter = {},
  query,
  options = {},
) => {
  if (!uuid) {
    return {};
  }
  const { dateRange, ads, apps, platform } = filter;
  const { combined = false, appGrouping = true } = options;
  let url = `${baseUrl}/${uuid}/data?${query}`;
  const params = {
    ads: ads || null,
    apps: apps || null,
    combinedData: combined,
    timezone: timezone || null,
    appGrouping: appGrouping
  };

  if (dateRange) {
    params.startDate = dateRange[0];
    params.endDate = dateRange[1];
  }
  if (platform) {
    params.platform = platform;
  }

  try {
    const response = await axios.post(url, params);
    return response.data;
  } catch (error) {
    let message = getFetchError(error);
    throw new Error('Failed to fetch campaign: ' + message);
  }
};
