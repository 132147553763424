import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { FaSortDown, FaSortUp, FaSort } from 'react-icons/fa';

import CSVExport from './CSVExport';
import { paginationNumbers } from 'utils/utils';

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array
};

const DEFAULT_PAGE_SIZE = 100;

const CustomTable = styled(Table)`
  font-size: 14px;
`;

const PageNumber = styled.div`
  width: 1.2em;
  text-align: center;
`;

function DataTable(props) {
  const { columns, data, exportOptions = {}, initialState = {} } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: DEFAULT_PAGE_SIZE, ...initialState }
    },
    useSortBy,
    usePagination
  );

  const getCSVData = () => {
    const { getData } = exportOptions;
    const data = getData(allColumns, rows);
    return data;
  };

  let paginationItems = [];

  const maxItems = 7;
  paginationNumbers(pageCount, pageIndex, maxItems).forEach(
    (pageNumber, idx) => {
      const key = pageNumber + '-' + idx;
      if (pageNumber === '...') {
        paginationItems.push(
          <Pagination.Item key={key} disabled>
            <PageNumber>...</PageNumber>
          </Pagination.Item>
        );
      } else {
        const active = pageNumber === pageIndex;
        paginationItems.push(
          <Pagination.Item
            key={key}
            active={active}
            onClick={() => gotoPage(pageNumber)}
          >
            <PageNumber>{pageNumber + 1}</PageNumber>
          </Pagination.Item>
        );
      }
    }
  );

  // Render the UI for your table
  return (
    <div>
      <CSVExport getData={getCSVData} filename={exportOptions.filename} />
      <CustomTable responsive bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className
                    },
                    column.getSortByToggleProps()
                  ])}
                >
                  <div className="d-flex justify-content-between">
                    <span>{column.render('Header')}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className
                        }
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </CustomTable>
      {(canNextPage || canPreviousPage) && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="text-muted my-2">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>
          <Pagination>
            <Pagination.First onClick={() => gotoPage(0)} />
            <Pagination.Prev
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            />
            {paginationItems}
            <Pagination.Next
              disabled={!canNextPage}
              onClick={() => nextPage()}
            />
            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} />
          </Pagination>
        </div>
      )}
    </div>
  );
}

export default DataTable;
