import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryTheme,
  VictoryTooltip,
  VictoryPie,
  VictoryContainer,
  VictoryLabel
} from 'victory';

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  x: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
  scale: PropTypes.number,
  innerPadding: PropTypes.number,
  centerLabels: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object
  }))
};

const ChartLabel = props => {
  return (
    <g>
      <VictoryLabel
        {...props}
        style={{ fontSize: 8 }}
        renderInPortal={true}
        text={d => {
          const percentage = d.datum.percentage;
          if (percentage && percentage > 3) {
            const label = d.datum.label ? `${d.datum.label}: ` : '';
            return `${label}${d.datum.percentage.toFixed(1)}%`;
          }
          return '';
        }}
      />
      <VictoryTooltip
        {...props}
        constrainToVisibleArea={true}
        orientation="top"
        pointerLength={0}
        cornerRadius={3}
        style={{ fontSize: 8, fill: '#ccc' }}
        flyoutStyle={{ fill: '#222', strokeWidth: 1, stroke: '#444' }}
      />
    </g>
  );
};

ChartLabel.defaultEvents = VictoryTooltip.defaultEvents;

function PieChart(props) {
  const { data, x, y, scale = 0.9, innerPadding = 0.14 } = props;
  const size = { width: 250 * scale, height: 250 * scale };
  return (
    <div className="d-flex align-items-center justify-content-center">
      <VictoryContainer {...size} style={{ width: `${100 * scale}%` }}>
        <VictoryPie
          standalone={false}
          {...size}
          padding={size.width * 0.16}
          theme={VictoryTheme.material}
          colorScale={'qualitative'}
          data={data}
          responsive={false}
          x={x}
          y={y}
          innerRadius={size.width * innerPadding}
          labelPosition="centroid"
          labels={d => {
            let label = d.datum[x];
            if (d.datum.percentage !== undefined) {
              label += ` (${d.datum.percentage.toFixed(1)}%)`;
            }
            return label;
          }}
          labelComponent={<ChartLabel />}
        />
        {props.centerLabels &&
          props.centerLabels.map((label, idx) => (
            <VictoryLabel
              key={idx}
              textAnchor="middle"
              style={{
                fontSize: 10,
                fontFamily: "'HeleveticaNeue', 'Helvetica', 'Arial', sans-serif",
                ...(label.style || {})
              }}
              x={size.width / 2}
              y={(size.height / 2) - ((props.centerLabels.length * 7) / 2)}
              dy={14*idx}
              text={label.text}
            />
          ))}
      </VictoryContainer>
    </div>
  );
}

export default PieChart;
