import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'react-moment';
import Logo from 'common/RichieLogo';
import Container from 'react-bootstrap/Container';

const Title = styled.h4`
  font-weight: bold;
  flex: 1;
`;

const Header = props => {
  const { title, dates, dateFormat = 'DD.MM.YYYY' } = props;
  return (
    <Container>
      <div className="d-flex justify-content-between border-bottom pb-2 mt-4 mb-2 align-items-center">
        <Title className="">
          {title}
          <div>
            <small className="text-muted">
              ({dates.start && <Moment format={dateFormat}>{dates.start}</Moment>}
              &nbsp;-&nbsp;
              {dates.end && <Moment format={dateFormat}>{dates.end}</Moment>})
            </small>
          </div>
        </Title>
        <Logo width={100} />
      </div>
    </Container>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }).isRequired,
  dateFormat: PropTypes.string
};

export default Header;
