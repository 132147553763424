import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateRange: [],
  application: null,
  platform: null,
  timeResolution: 'days'
};

const dataFilter = createSlice({
  name: 'dataFilter',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      if (action.payload) {
        const { type, value } = action.payload;
        if (type in state) {
          state[type] = value;
        }
      }
    },
    resetFilters: (state, action) => {
      return {
        ...initialState
      }
    }
  }
});

export const { setFilter, resetFilters } = dataFilter.actions;

export default dataFilter.reducer;
