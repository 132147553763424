import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const Overlay = styled.div`
  position: ${props => (props.container ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${props => (props.container ? 10000 : 10001)};
  opacity: ${props => (props.show ? '1.0' : '0.0')};
  pointer-events: none;
  background-color: ${props =>
    props.container ? 'rgba(255,255,255,0.7)' : 'inherit'};
  transition: opacity 0.2s ease-in-out;
`;

const LoadingText = ({ text = 'Loading data...' }) => (
  <div
    className="d-flex mx-auto align-items-center justify-content-center rounded p-2 mt-3 text-white"
    style={{ width: 250, background: 'rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}
  >
    <Spinner animation="border" role="status" size="sm" className="mr-2">
      <span className="sr-only">{text}</span>
    </Spinner>
    {text}
  </div>
);

const CenterSpinner = () => (
  <div className="d-flex h-100 w-100 align-items-center justify-content-center">
    <Spinner animation="border" role="status" className="text-secondary">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

function OverlaySpinner(props) {
  return (
    <Overlay {...props}>
      {!props.container && <LoadingText />}
      {props.container && props.showSpinner && <CenterSpinner />}
    </Overlay>
  );
}

export default OverlaySpinner;
