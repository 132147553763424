import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import SectionCard from 'common/SectionCard';
import PieChart from 'common/PieChart';
import EventsChart from './EventsChart';
import DetailsChart from './DetailsChart';

VideoDetails.propTypes = {
  data: PropTypes.object
};

function VideoDetails(props) {
  const { data, loading } = props;

  const watched = (data.watched_100 / data.started) * 100;
  const unwatched = 100 - watched;

  return (
    <SectionCard title="Video details" loading={loading}>
      <Row>
        <Col md={12} lg={7}>
          <h5>Video events</h5>
          <hr />
          <EventsChart data={data} />
        </Col>
        <Col md={12} lg={5}>
          <h5>Video completion rate</h5>
          <hr />
          <PieChart
            data={[
              { name: 'Watched', value: watched },
              { name: 'Not watched', value: unwatched }
            ]}
            x="name"
            y="value"
            scale={0.6}
            innerPadding={0.27}
            centerLabels={[
              {
                text: `${watched.toFixed(1)}%`,
                style: { fontSize: 15, fontWeight: 'bold' }
              },
              { text: 'COMPLETED', style: { fontSize: 8 } }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Video play time details</h5>
          <hr />
          <DetailsChart data={data.details} />
        </Col>
      </Row>
    </SectionCard>
  );
}

export default VideoDetails;
