import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';

import styled from 'styled-components';
import moment from 'moment';
import Select from 'react-select';

import { setFilter, resetFilters } from './dataFilterSlice';
import { applicationSelector } from 'app/selectors';

import DatePicker from './DatePicker';
import Button from 'react-bootstrap/Button';

const ENABLE_STICKY_BAR = false;

const SelectContainer = styled.div`
  flex-basis: ${props => (props.width ? props.width + 'px' : '300px')};
  margin: 5px 5px 5px 0;
`;

const getApplicationObject = app =>
  app
    ? {
        value: app.slug,
        label: app.title
      }
    : null;

const platforms = [
  { value: 'ios', label: 'iOS' },
  { value: 'android', label: 'Android' },
  { value: 'html5', label: 'Web' }
];

const DataFilter = props => {
  const [filtersDirty, setFiltersDirty] = React.useState(false);
  const { start: campaignStart, end: campaignEnd } = useSelector(
    state => state.campaign.details
  );
  const { dateRange, application, platform } = useSelector(
    state => state.dataFilter
  );
  const applications = useSelector(applicationSelector);

  const initialDateRangePickerState = [];
  if (dateRange[0] && moment(dateRange[0]).isValid()) {
    initialDateRangePickerState[0] = moment(dateRange[0]);
  }
  if (dateRange[1] && moment(dateRange[1]).isValid()) {
    initialDateRangePickerState[1] = moment(dateRange[1]);
  }

  const dispatch = useDispatch();

  // unmount
  React.useEffect(() => {
    return () => {
      dispatch(resetFilters());
    }
  }, [dispatch]);

  const apps = useMemo(() => {
    return applications && applications.map(app => getApplicationObject(app));
  }, [applications]);

  const [filters, setFilters] = React.useState({
    application: apps.find(a => a.value === application) ?? null,
    platform: platforms.find(p => p.value === platform) ?? null
  });

  const applyFilters = useCallback(
    clear => {
      batch(() => {
        Object.entries(filters).forEach(([type, value]) => {
          dispatch(setFilter({ type, value }));
          setFiltersDirty(false);
        });
      });
    },
    [dispatch, filters]
  );

  const handleInputChange = useCallback((type, value) => {
    setFilters(filters => ({
      ...filters,
      [type]: value
    }));
    setFiltersDirty(true);
  }, []);

  const handleDateRangeChange = useCallback(dateRange => {
    setFilters(filters => ({ ...filters, dateRange: dateRange }));
    setFiltersDirty(true);
  }, []);

  const [isSticky, setSticky] = React.useState(false);
  const ref = React.useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  React.useEffect(() => {
    if (ENABLE_STICKY_BAR) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', () => handleScroll);
      };
    }
  }, []);

  return (
    <div
      ref={ref}
      className={`bg-white ${isSticky ? 'sticky-top is-sticky' : ''}`}
      style={{ transition: 'all 0.3s' }}
    >
      <div className="container d-flex justify-content-start align-item-center flex-wrap py-1">
        <SelectContainer width={230}>
          <Select
            options={apps}
            isClearable={true}
            isMulti={false}
            placeholder="Filter by application..."
            value={apps.find(a => a.value === filters.application) ?? null}
            onChange={app =>
              handleInputChange('application', app ? app.value : null)
            }
          />
        </SelectContainer>
        <SelectContainer width={200}>
          <Select
            options={platforms}
            isClearable={true}
            isMulti={false}
            placeholder="Filter by platform..."
            value={platforms.find(p => p.value === filters.platform) ?? null}
            onChange={platform => {
              handleInputChange('platform', platform ? platform.value : null);
            }}
          />
        </SelectContainer>
        {campaignStart && campaignEnd && (
          <SelectContainer width={300}>
            <DatePicker
              dateRange={initialDateRangePickerState}
              onChange={handleDateRangeChange}
              minDate={moment(campaignStart)}
              maxDate={moment(campaignEnd)}
            />
          </SelectContainer>
        )}
        <div className="d-flex align-items-center ml-auto">
          <Button
            size="sm"
            variant={filtersDirty ? 'info' : 'secondary'}
            onClick={applyFilters}
            disabled={!filtersDirty}
          >
            Apply filters
          </Button>
        </div>
      </div>
    </div>
  );
};

DataFilter.propTypes = {};

export default React.memo(DataFilter);
