import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import { createDateRange, trimString } from '../utils/utils';
import config from 'config';

const timezone = config.TIMEZONE;

const resultKeyMap = {
  impressions: 'impressions',
  clicks: 'clicks',
  custom: 'customEvents'
};

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const availableDatesSelector = createSelector(
  state => state.campaign.details,
  campaign => {
    if (!campaign) {
      return [];
    }
    const availableDates = createDateRange(campaign.start, campaign.end, {
      timezone: timezone
    });
    return availableDates;
  }
);

export const timeResolutionSelector = (_, timeResolution) => ({
  currentResolution: timeResolution || 'days'
});

// create ad map by uuid
export const adMapSelector = createSelector(
  state => state.campaign.ads,
  ads => {
    if (Array.isArray(ads)) {
      return ads.reduce((d, ad) => {
        if (ad.uuid) {
          d[ad.uuid] = ad;
        }
        return d;
      }, {});
    }
    return {};
  }
);

export const adsSelector = createSelector(
  state => state.campaign.ads,
  state => state.campaign.apps,
  state => state.dataFilter.application,
  (ads, apps, selectedApp) => {
    if (!apps || !selectedApp) {
      return ads;
    }
    const availableAds = Object.entries(apps).reduce((m, [appSlug, app]) => {
      if (appSlug === selectedApp) {
        return [...m, ...app.ads];
      }
      return m;
    }, []);
    return ads.filter(ad => {
      return availableAds.includes(ad.uuid);
    });
  }
);

// filter data for selected apps
export const appDataSelector = createSelector(
  state => state.dataFilter.application,
  state => state.campaign.data,
  state => state.campaign.appGrouping,
  (application, data, appGrouping) => {
    if (!application || !appGrouping) {
      return data; // no filtering
    }
    const selectedApps = [application]; // keep code for multiple selected apps
    const subData = Object.entries(data).reduce((m, [key, ad]) => {
      const appData = selectedApps.reduce((m, id) => {
        if (ad[id]) {
          m[id] = ad[id];
        }
        return m;
      }, {});
      m[key] = appData;
      return m;
    }, {});
    return subData;
  }
);

// filter data for selected ads
export const adDataSelector = createSelector(
  state => state.campaign.selectedAds,
  appDataSelector,
  (selectedAds, data) => {
    if (!selectedAds || !data) {
      return [];
    }
    const subData = selectedAds.reduce((m, id) => {
      if (data[id]) {
        m[id] = data[id];
      }
      return m;
    }, {});
    return subData;
  }
);

export const dateRangeSelector = createSelector(
  state => state.campaign.details,
  state => state.dataFilter.dateRange,
  (campaign, dateRange = []) => {
    if (!campaign) {
      return {};
    }

    const campaignStart = moment(campaign.start);
    const campaignEnd = moment(campaign.end);
    const rangeStart = moment(dateRange[0]);
    const rangeEnd = moment(dateRange[1]);

    let startDate = campaignStart;
    let endDate = campaignEnd;

    if (dateRange[0] && rangeStart.isValid()) {
      startDate = moment.max(campaignStart, rangeStart);
    }

    if (dateRange[1] && rangeEnd.isValid()) {
      endDate = moment.min(campaignEnd, rangeEnd);
    }

    return {
      startDate: startDate.tz(timezone).startOf('day'),
      endDate: endDate.tz(timezone).endOf('day')
    };
  }
);

export const availableTimeResolutionsSelector = createSelector(
  state => state.campaign.details,
  dateRangeSelector,
  (campaign, dateRange) => {
    if (!campaign) {
      return [];
    }

    const first = dateRange.startDate;
    const last = dateRange.endDate;

    const isDisabled = unit => {
      // disable resolution for unit, if there are less than two datapoints
      // linegraph with one datapoint isn't useful
      return last.diff(first, unit) < 2;
    };

    const resolutions = [
      { label: 'Hours', value: 'hours', disabled: false }, // hours should be always enabled
      { label: 'Days', value: 'days', disabled: isDisabled('days') },
      { label: 'Weeks', value: 'weeks', disabled: isDisabled('weeks') },
      { label: 'Months', value: 'months', disabled: isDisabled('months') }
    ];

    return resolutions;
  }
);

// flatten data and filter for selected day
export const flattenAndFilterDataSelector = createSelector(
  [
    adDataSelector,
    dateRangeSelector,
    availableTimeResolutionsSelector,
    state => state.campaign.appGrouping
  ],
  (data, dateRange, timeResolutions, appGrouping = true) => {
    const filteredData = {};
    const resolutions = timeResolutions.map(r => {
      filteredData[r.value] = {
        byTime: {},
        adData: {},
        total: {
          impressions: 0,
          clicks: 0,
          custom: 0
        }
      };
      return r.value;
    });

    if (!data || data.length === 0) {
      return filteredData;
    }

    const startDate = dateRange.startDate && dateRange.startDate.toDate();
    const endDate = dateRange.endDate && dateRange.endDate.toDate();

    // derives and calculates the actual data
    const mapper = ({
      key,
      type,
      resolutions,
      filteredData,
      startDate,
      endDate,
      adUUID,
      appSlug
    }) => {
      const mappedKey = resultKeyMap[key];

      resolutions.forEach(currentResolution => {
        const breakdownType =
          currentResolution === 'hours' ? 'hourlyBreakdown' : 'dailyBreakdown';

        const breakdown = type[breakdownType];
        const dataProperty = filteredData[currentResolution];

        if (!breakdown) {
          // no requested breakdown available
          return;
        }

        breakdown.forEach(row => {
          const dateObj = new Date(row.time);
          if (
            startDate &&
            endDate &&
            (dateObj.getTime() < startDate.getTime() ||
              dateObj.getTime() > endDate.getTime())
          ) {
            return;
          }
          const time = moment
            .tz(row.time, timezone)
            .startOf(currentResolution)
            .toISOString();

          if (!dataProperty.byTime[time]) {
            dataProperty.byTime[time] = {
              uniques: {
                impressions: 0,
                clicks: 0,
                custom: 0
              }
            };
          }

          if (!dataProperty.byTime[time][key]) {
            dataProperty.byTime[time][key] = 0;
          }

          if (!dataProperty.byTime[time].uniques[key]) {
            dataProperty.byTime[time].uniques[key] = 0;
          }

          dataProperty.byTime[time][key] += row[mappedKey];

          if (row.newUniques) {
            dataProperty.byTime[time].uniques[key] += row.newUniques;
          }

          // unique key for given row, used to group impressions/clicks/custom
          const adDataKey = appSlug
            ? [time, appSlug, adUUID].join(':')
            : [time, adUUID].join(':');
          if (!dataProperty.adData[adDataKey]) {
            dataProperty.adData[adDataKey] = {
              time,
              appSlug,
              adUUID,
              impressions: 0,
              clicks: 0,
              custom: 0
            };
          }
          dataProperty.adData[adDataKey][key] += row[mappedKey];

          if (!dataProperty.total[key]) {
            dataProperty.total[key] = 0;
          }
          dataProperty.total[key] += row[mappedKey];
        });
      });
    };

    // ad level
    Object.entries(data).forEach(([adUUID, ad]) => {
      // app level
      if (!ad) {
        return;
      }
      if (appGrouping) {
        Object.entries(ad).forEach(([appSlug, app]) => {
          // item type level (impressions, clicks, events)
          Object.entries(app).forEach(([key, type]) => {
            mapper({
              key,
              type,
              resolutions,
              filteredData,
              startDate,
              endDate,
              adUUID,
              appSlug
            });
          });
        });
      } else {
        Object.entries(ad).forEach(([key, type]) => {
          // item type level (impressions, clicks, events)
          mapper({
            key,
            type,
            resolutions,
            filteredData,
            startDate,
            endDate,
            adUUID
          });
        });
      }
    });
    return filteredData;
  }
);

const calculateEngagement = (data, names) => {
  const s1Event =
    names &&
    names.find(item => {
      return item.name[1] === 'changeSection' && item.name[3] === 's1';
    });

  const video3sEvent =
    names &&
    names.find(
      item =>
        item.name[0] === 'video' &&
        item.name[1] === 'watched' &&
        item.name[2] === '3sec'
    );

  const hasS1Event = s1Event ? s1Event.count > 0 : false;
  const hasVideo3sEvent = video3sEvent ? video3sEvent.count > 0 : false;

  let custom = data.custom;

  if (hasS1Event) {
    custom = s1Event.count;
  } else if (hasVideo3sEvent) {
    custom = video3sEvent.count;
  }
  const engagement =
    data.impressions && data.impressions > 0
      ? ((data.clicks + custom) / data.impressions) * 100
      : 0;

  return {
    engagement,
    multipageAdDetected: hasS1Event,
    videoAdDetected: hasVideo3sEvent,
    s1Events: hasS1Event ? s1Event.count : 0,
    videoEvents: hasVideo3sEvent ? video3sEvent.count : 0
  };
};

export const adTotalDataSelector = createSelector(
  [appDataSelector, dateRangeSelector, state => state.campaign.appGrouping],

  (data, dateRange, appGrouping) => {
    // ad level
    let total = {};
    const startDate = dateRange.startDate && dateRange.startDate.toDate();
    const endDate = dateRange.endDate && dateRange.endDate.toDate();
    const mapper = ({ total, key, type, adUUID }) => {
      const mappedKey = resultKeyMap[key];
      if (type && type.dailyBreakdown) {
        type.dailyBreakdown.forEach(row => {
          const dateObj = new Date(row.time);
          if (
            startDate &&
            endDate &&
            (dateObj.getTime() < startDate.getTime() ||
              dateObj.getTime() > endDate.getTime())
          ) {
            return; // outside date range
          }
          total[adUUID][key] += row[mappedKey];
        });
      }
    };
    Object.entries(data).forEach(([adUUID, ad]) => {
      // app level
      if (!ad) {
        return;
      }
      Object.entries(ad).forEach(([app, row]) => {
        if (!total[adUUID]) {
          total[adUUID] = {
            impressions: 0,
            clicks: 0,
            custom: 0,
            engagement: 0
          };
        }
        if (appGrouping) {
          Object.entries(row).forEach(([key, type]) => {
            mapper({
              total,
              key,
              type,
              adUUID
            });
            if (total[adUUID].impressions > 0) {
              const { engagement } = calculateEngagement(total[adUUID]);
              total[adUUID].engagement = engagement;
            }
          });
        } else {
          mapper({
            total,
            key: app,
            type: row,
            adUUID
          });
          if (total[adUUID].impressions > 0) {
            // without appgrouping, we can calculate engagement for section ads
            const { engagement } = calculateEngagement(
              total[adUUID],
              ad.custom.names
            );
            total[adUUID].engagement = engagement;
          }
        }
      });
    });
    return total;
  }
);

// derive overview data from raw subdata
export const overviewSelector = createSelector(
  state => state.campaign.combinedData,
  combinedData => {
    if (!combinedData || !combinedData.data) {
      return {
        impressions: 0,
        clicks: 0,
        custom: 0,
        engagement: 0
      };
    }

    const data = combinedData.data;

    const overviewData = {
      impressions: data?.impressions?.impressions ?? 0,
      clicks: data?.clicks?.clicks ?? 0,
      custom: data?.custom?.customEvents ?? 0,
      engagement: 0
    };

    if (overviewData.impressions > 0) {
      const { engagement, multipageAdDetected, s1Events, videoAdDetected, videoEvents } = calculateEngagement(
        overviewData,
        data.custom.names
      );
      overviewData.engagement = engagement;
      overviewData.multipageAdDetected = multipageAdDetected;
      overviewData.s1Events = s1Events;
      overviewData.videoAdDetected = videoAdDetected;
      overviewData.videoEvents = videoEvents;
    }

    return overviewData;
  }
);

// derive impression chart data from raw subdata
export const impressionChartSelector = createSelector(
  state => state.campaign.details,
  dateRangeSelector,
  timeResolutionSelector,
  flattenAndFilterDataSelector,
  (campaign, dateRange, { currentResolution }, data) => {
    if (
      !campaign ||
      !data ||
      Object.keys(data[currentResolution].byTime).length === 0
    ) {
      return [];
    }

    const start = dateRange.startDate;
    const end = dateRange.endDate;
    const dateArray = createDateRange(start, end, {
      timezone: timezone,
      resolution: currentResolution
    });

    const arrayData = dateArray.map(date => {
      const d = {
        date: moment.tz(date, timezone).toDate(),
        impressions: 0,
        interactions: 0,
        newImpressions: 0
      };
      if (data[currentResolution].byTime[date]) {
        const {
          impressions = 0,
          clicks = 0,
          custom = 0,
          uniques = { impressions: 0 }
        } = data[currentResolution].byTime[date];
        d.impressions = impressions;
        d.interactions = clicks + custom;
        d.newImpressions = uniques.impressions;
      }
      return d;
    });

    const hasLeadingZero = arr => {
      const last = arr[arr.length - 1];
      if (!last) {
        return false;
      }
      return last.impressions === 0 && last.interactions === 0;
    };
    // strip zero elements from the end
    while (hasLeadingZero(arrayData)) {
      arrayData.pop();
    }

    return arrayData;
  }
);

export const customEventsSelector = createSelector(
  state => state.campaign.combinedData.data,
  data => {
    if (data && data.custom && data.custom.names) {
      const arrayData = data.custom.names
        .filter(row => {
          return row.name[0] !== 'error';
        })
        .map(row => {
          const { count, name } = row;
          return {
            name: name.join(' \u21D2 '),
            count: count,
            percentage:
              data.custom.customEvents > 0
                ? (count / data.custom.customEvents) * 100
                : 0
          };
        });
      return arrayData.sort((a, b) => {
        return b.count - a.count;
      });
    }
    return [];
  }
);

export const urlSelector = createSelector(
  state => state.campaign.combinedData.data,
  data => {
    if (data && data.clicks && data.clicks.URLs) {
      const arrayData = data.clicks.URLs.map(row => {
        const { clicks, URL: url } = row;

        return {
          name: trimString(url, 50),
          url: url,
          clicks: clicks,
          percentage:
            data.clicks.clicks > 0 ? (clicks / data.clicks.clicks) * 100 : 0
        };
      });
      return arrayData.sort((a, b) => {
        return b.clicks - a.clicks;
      });
    }
    return [];
  }
);

export const videoEventsSelector = createSelector(
  state => state.campaign.combinedData.data,
  data => {
    if (data && data.richieInternal && data.richieInternal.names) {
      const videoData = {
        started: 0,
        watched_3s: 0,
        watched_0: 0,
        watched_25: 0,
        watched_50: 0,
        watched_75: 0,
        watched_100: 0,
        with_sound: 0,
        details: []
      };
      const videoDetails = range(0, 100, 5).reduce((m, percent) => {
        m[percent] = 0;
        return m;
      }, {});

      const videoEvents = data.richieInternal.names.filter(row => {
        return row.name[0] === 'video';
      });

      if (videoEvents.length === 0) {
        return null;
      }

      videoEvents.forEach(row => {
        const { count, name } = row;

        if (name[1] === 'started_once') {
          videoData.started += count;
        } else if (name[1] === 'session') {
          const [total, sound, duration] = name.slice(2);
          const percentage = Math.round((duration / total) * 100);
          if (duration >= 3) {
            videoData.watched_3s += count;
          }

          if (percentage >= 100) {
            videoData.watched_100 += count;
          } else if (percentage >= 75) {
            videoData.watched_75 += count;
          } else if (percentage >= 50) {
            videoData.watched_50 += count;
          } else if (percentage >= 25) {
            videoData.watched_25 += count;
          } else {
            videoData.watched_0 += count;
          }

          if (sound === 'YES') {
            videoData.with_sound += count;
          }
          let detailsPercentage = Math.floor(percentage / 5) * 5;
          if (!videoDetails[detailsPercentage]) {
            videoDetails[detailsPercentage] = 0;
          }
          videoDetails[detailsPercentage] += count;
        }
      });

      const details = Object.entries(videoDetails).map(
        ([percentage, count]) => ({
          percentage: percentage,
          count: count
        })
      );

      videoData.details = details.length > 0 ? details : [];
      return videoData;
    } else {
      return null;
    }
  }
);

export const dataTableSelector = createSelector(
  state => state.campaign.apps,
  flattenAndFilterDataSelector,
  timeResolutionSelector,
  adMapSelector,
  (apps = {}, data, { currentResolution = 'days' }, adMap) => {
    if (!data || !data[currentResolution]) {
      return [];
    }

    const flattenData = data[currentResolution];

    const adData = Object.entries(flattenData.adData).map(([key, data]) => {
      const appTitle = apps[data.appSlug]
        ? apps[data.appSlug].title
        : data.appSlug;
      const adName = adMap[data.adUUID] ? adMap[data.adUUID].name : '<Unknown>';

      const { engagement } = calculateEngagement(data);
      return {
        ...data,
        adName: adName,
        appTitle: appTitle,
        engagement: engagement
      };
    });
    return adData;
  }
);

export const uniquesSelector = createSelector(
  state => state.campaign.combinedData,
  combinedData => {
    if (!combinedData) {
      return {};
    }
    const { data } = combinedData;

    if (!data) {
      return {};
    }

    const uniques = {
      impressions: data?.impressions?.uniques ?? 0,
      clicks: data?.clicks?.uniques ?? 0,
      custom: data?.custom?.uniques ?? 0
    };
    return {
      ...uniques,
      engagement:
        uniques.impressions > 0
          ? ((uniques.clicks + uniques.custom) / uniques.impressions) * 100
          : 0
    };
  }
);

export const stopDurationsSelector = createSelector(
  state => state.campaign.combinedData,
  combinedData => {
    if (!combinedData) {
      return {};
    }
    const { data } = combinedData;

    if (!data || !data.impressions) {
      return {};
    }
    const { impressions, stopDurations } = data.impressions;
    const result = {
      stopping: [],
      durations: {},
      stoppingPercentage: 0
    };
    if (impressions && stopDurations) {
      const stopping = stopDurations.stoppingVisitors;
      const passing = impressions - stopping;
      result.stopping = [
        {
          type: 'passing',
          value: passing,
          percentage: (passing / impressions) * 100,
          label: 'Passing'
        },
        {
          type: 'stopping',
          value: stopping,
          percentage: (stopping / impressions) * 100,
          label: 'Stopping'
        }
      ];
      result.stoppingPercentage = (stopping / impressions) * 100;
      result.durations = {
        mean: stopDurations.meanTotalStopDurationSeconds,
        top10: stopDurations.meanTotalStopDurationSecondsTop10Percent,
        top30: stopDurations.meanTotalStopDurationSecondsTop30Percent
      };
    }

    return result;
  }
);

export const applicationSelector = createSelector(
  state => state.campaign.apps,
  apps => {
    if (!apps) {
      return [];
    }
    const applications = Object.entries(apps).map(([slug, app]) => {
      return {
        slug,
        title: app.title
      };
    });
    return applications;
  }
);
