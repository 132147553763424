import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Item from './Item';
import { useSelector } from 'react-redux';
import OverlaySpinner from 'app/OverlaySpinner';

const OverviewContainer = styled.div`
  margin: 0 0 15px 0;
  width: 100%;
  position: relative;
`;

const items = [
  {
    slug: 'impressions',
    label: 'Impressions'
  },
  { slug: 'clicks', label: 'Clicks' },
  {
    slug: 'custom',
    label: 'Events',
    description: 'Total number of interactions on a rich media ad.'
  },
  {
    slug: 'engagement',
    label: 'Engagement',
    description:
      'The ratio of ad interactions and clicks to an external URL to the number of times ad was displayed. (Events + Clicks) / Impressions.',
    unit: '%',
    fractions: 2
  }
];

const uniqueItems = [
  {
    slug: 'impressions',
    label: 'Unique impressions',
    description: 'Unique impressions (new users only)'
  },
  { slug: 'clicks', label: 'Unique clicks' },
  {
    slug: 'custom',
    label: 'Unique events'
  }
];

const Overview = props => {
  const { data = {}, uniquesData = {}, loading } = props;
  const showUniques = useSelector(state => state.campaign.showUniques);
  const itemObjects = items.map((d, i) => {
    const multipageAdEngagement =
      d.slug === 'engagement' && data.multipageAdDetected;
    const videoAdEngagement = d.slug === 'engagement' && data.videoAdDetected;
    const multipageAdCustom = d.slug === 'custom' && data.multipageAdDetected;
    const videoAdCustom = d.slug === 'custom' && data.videoAdDetected;
    let description = d.description;

    if (multipageAdEngagement) {
      description = 'The ratio of first section interactions and clicks to an external URL to the number of times ad was displayed. (First section changes + Clicks) / Impressions.';
    } else if (videoAdEngagement) {
      description = 'The ratio of video 3s watches and clicks to an external URL to the number of times ad was displayed. (Video 3s watches + Clicks) / Impressions.';
    }

    let details = null;

    if (multipageAdCustom) {
      details = <span>{data.s1Events.toLocaleString()} s1 events</span>;
    } else if (multipageAdEngagement) {
      details = 'using s1 events';
    } else if (videoAdCustom) {
      details = <span>{data.custom !== data.videoEvents ? data.videoEvents : ''} watched over 3 seconds</span>;
    } else if (videoAdEngagement) {
      details = 'using video plays over 3 seconds';
    }

    return (
      <Col className="my-2" key={i}>
        <Item
          label={d.label}
          value={data[d.slug]}
          description={description || null}
          unit={d.unit}
          fractions={d.fractions}
          details={details}
        />
      </Col>
    );
  });

  return (
    <OverviewContainer>
      <OverlaySpinner container={true} show={loading} />
      <Row className="row-cols-md-4 row-cols-2">{itemObjects}</Row>
      {showUniques && (
        <div className="position-relative">

          <Row
            className="row-cols-md-4 row-cols-2"
          >
            {uniqueItems.map((d, i) => {
              return (
                <Col className="my-2" key={i}>
                  <Item
                    label={d.label}
                    value={uniquesData?.[d.slug] ?? 0}
                    description={d.description || null}
                    unit={d.unit}
                    fractions={d.fractions}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </OverviewContainer>
  );
};

Overview.propTypes = {
  data: PropTypes.object
};

export default Overview;
