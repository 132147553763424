import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryTheme
} from 'victory';

const EventsChart = ({ data }) => {
  return (
    <VictoryChart
      horizontal
      domainPadding={{ x: 10 }}
      padding={{
        top: 0,
        left: 130,
        right: 40,
        bottom: 30
      }}
      height={150}
      minDomain={0}
      style={{
        parent: {
          height: '80%'
        }
      }}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 8
          }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: {
            fontSize: 8
          }
        }}
      />
      <VictoryBar
        barRatio={1}
        data={[
          { x: 'Video started', y: data.started },
          { x: 'Watched first quartile (25%)', y: data.watched_25 },
          { x: 'Midpoint (50%)', y: data.watched_50 },
          { x: 'Third quartile (75%)', y: data.watched_75 },
          { x: 'Video completed (100%)', y: data.watched_100 },
          { x: 'Watched the video with sound', y: data.with_sound }
        ].reverse()}
        labels={({ datum }) => datum.y}
        labelComponent={
          <VictoryLabel
            dx={5}
            style={{ fontSize: 10, fill: 'black' }}
            textAnchor="start"
          />
        }
      />
    </VictoryChart>
  );
};

export default EventsChart;
