import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import moment from 'moment';

import SectionCard from 'common/SectionCard';
import DataTable from 'common/DataTable';
import TimeResolutionPicker from 'common/TimeResolutionPicker';

import { trimString } from 'utils/utils';

import {
  availableTimeResolutionsSelector,
  dataTableSelector
} from 'app/selectors';

DetailsTable.propTypes = {
  data: PropTypes.array,
  toolbar: PropTypes.element
};

const TotalFooter = ({ rows, column }) => {
  // Only calculate total if rows change
  const total = React.useMemo(
    () => rows.reduce((sum, row) => row.values[column.id] + sum, 0),
    [rows, column]
  );

  return (
    <div className="text-right">
      <strong>{total}</strong>
    </div>
  );
};

const AverageFooter = info => {
  const { rows, column } = info;
  const total = React.useMemo(
    () => rows.reduce((sum, row) => row.values[column.id] + sum, 0),
    [rows, column]
  );

  return (
    <div className="text-right">
      <strong>{(total / rows.length).toFixed(1)}%</strong>
    </div>
  );
};

function getDateFormat(timeResolution) {
  let format = 'DD.MM.YYYY';
  switch (timeResolution) {
    case 'hours':
      format = 'DD.MM.YYYY HH:mm';
      break;
    case 'days':
      format = 'DD.MM.YYYY';
      break;
    case 'weeks':
      format = '[Week] w';
      break;
    case 'months':
      format = 'MMM YYYY';
      break;
    default:
      break;
  }
  return format;
}

function DetailsTable({loading}) {
  const [timeResolution, setTimeResolution] = useState('days');
  const availableTimeResolutions = useSelector(
    availableTimeResolutionsSelector
  );
  const { appGrouping } = useSelector(state => state.campaign);

  const data = useSelector(state => dataTableSelector(state, timeResolution));

  const handleResolutionChange = useCallback(resolution => {
    setTimeResolution(resolution);
  }, []);

  const toolbar = (
    <TimeResolutionPicker
      value={timeResolution}
      resolutions={availableTimeResolutions}
      onChange={handleResolutionChange}
      allEnabled={true}
    />
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Date',
        accessor: row => new Date(row.time),
        id: 'date',
        sortType: 'datetime',
        Cell: ({ cell }) => {
          return React.useMemo(() => {
            let format = getDateFormat(timeResolution);
            return <Moment format={format}>{cell.value}</Moment>;
          }, [cell.value]);
        }
      },
      appGrouping ? { Header: 'Application', accessor: 'appTitle' } : null,
      {
        Header: 'Name',
        accessor: 'adName',
        Cell: ({ cell }) => trimString(cell.value, 40)
      },
      {
        Header: 'Impressions',
        accessor: 'impressions',
        sortType: 'basic',
        className: 'text-right',
        Footer: TotalFooter,
        sortDescFirst: true
      },
      {
        Header: 'Clicks',
        accessor: 'clicks',
        sortType: 'basic',
        className: 'text-right',
        Footer: TotalFooter,
        sortDescFirst: true
      },
      {
        Header: 'Events',
        accessor: 'custom',
        sortType: 'basic',
        className: 'text-right',
        Footer: TotalFooter,
        sortDescFirst: true
      },
      {
        Header: 'Engagement',
        accessor: 'engagement',
        sortType: 'basic',
        Cell: ({ cell }) => {
          return React.useMemo(() => {
            const val = cell.value.toFixed(1) + '%';
            return <div className="text-right">{val}</div>;
          }, [cell.value]);
        },
        Footer: AverageFooter,
        sortDescFirst: true
      }
    ].filter(Boolean); // filter falsy items
  }, [timeResolution, appGrouping]);

  const tableData = React.useMemo(() => {
    return data;
  }, [data]);

  const initialState = React.useMemo(() => {
    return {
      sortBy: [{ id: 'date', desc: false }]
    };
  }, []);

  const exportOptions = {
    filename: 'campaign-data.csv',
    getData: (columns, rows) => {
      const headers = columns.map(c => c.id);
      const data = rows.map(row => {
        return headers.map(h => {
          if (h === 'date') {
            const dateFormat = getDateFormat(timeResolution);
            return moment(row.values[h]).format(dateFormat);
          } else if (h === 'engagement') {
            return row.values[h].toFixed(1) + '%';
          }
          return row.values[h];
        });
      });
      return [headers, ...data];
    }
  };

  return (
    <SectionCard title="Data" toolbar={toolbar} loading={loading}>
      <DataTable
        columns={columns}
        data={tableData}
        initialState={initialState}
        exportOptions={exportOptions}
      />
    </SectionCard>
  );
}

export default DetailsTable;
