import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';

const pluralize = (value, noun, suffix = 's') =>
  `${value} ${noun}${value !== 1 ? suffix : ''}`;

const Duration = ({ duration, verbose }) => {
  let format = '%m %s';

  if (duration < 60 && verbose) {
    format = '%s';
  } else if (duration >= 60 * 60) {
    format = '%h %m %s';
  }

  const momentDuration = moment.duration(duration * 1000);

  if (!momentDuration.isValid()) {
    return <span>Invalid duration</span>;
  }

  const units = ['hour', 'minute', 'second'];

  // get unit values
  const [hours, minutes, seconds] = units.map(unit => {
    const val = momentDuration.get(unit);
    if (verbose) {
      return pluralize(val, unit);
    } else {
      return `${val} ${unit[0]}`;
    }
  });

  let output = format
    .replace('%h', hours)
    .replace('%m', minutes)
    .replace('%s', seconds);

  return <span>{output}</span>;
};

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
  verbose: PropTypes.bool
};

export default Duration;
