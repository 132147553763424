import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';

const Value = styled.div`
  color: #222;
  font-size: 25px;
  font-family: 'HelveticaNeueCondensedBold', 'HelveticaNeue-CondensedBold',
    'Helvetica Neue Condensed Bold', 'HelveticaNeueBold', 'HelveticaNeue-Bold',
    'Helvetica Neue Bold', 'HelveticaNeue', 'Helvetica Neue',
    'TeXGyreHerosCnBold', 'Helvetica', 'Tahoma', 'Geneva', 'Arial Narrow',
    'Arial', sans-serif;
  letter-spacing: +0.05em;
  font-weight: 600;
  font-stretch: condensed;
`;

const Title = styled.div`
  color: #777;
  font-size: 95%;
`;

const TooltipContainer = styled.span`
  vertical-align: text-top;
  font-size: 12px;
`;

const Item = props => {
  const { label, description, unit, fractions, value, details } = props;
  let derivedValue = value;
  if (fractions) {
    derivedValue = derivedValue.toFixed(fractions);
  } else {
    derivedValue.toLocaleString();
  }
  return (
    <Card className="item">
      <Card.Body className="px-2 py-4 position-relative">
        {value !== undefined && (
          <>
          <Value className="text-center">
            &nbsp;{derivedValue.toLocaleString()} {unit}&nbsp;
          </Value>
          <div className="position-absolute text-center text-muted" style={{left:0, right:0, bottom: 5, fontSize: '80%'}}>{details}</div>
          </>
        )}
      </Card.Body>
      <Card.Footer>
        <Title className="text-center">
          {label}
          &nbsp;
          {description && description.length > 0 && (
            <TooltipContainer>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{description}</Tooltip>}
              >
                <FaQuestionCircle />
              </OverlayTrigger>
            </TooltipContainer>
          )}
        </Title>
      </Card.Footer>
    </Card>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  fractions: PropTypes.number,
  unit: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.node
};

export default Item;
