import React, { useEffect, useCallback } from 'react';
import { Container, Toast } from 'react-bootstrap';
import { useSelector, useDispatch, batch } from 'react-redux';
import { parseHash } from 'utils/utils';
import { Helmet } from 'react-helmet';
import { FaBell } from 'react-icons/fa';

// Sections
import Header from '../features/header';
import Overview from '../features/overview';
import ImpressionChart from '../features/impressionChart';
import AdPicker from 'features/adPicker';
import DataFilter from '../features/dataFilter';
import ClickDetails from '../features/clickDetails';
import CustomDetails from '../features/customDetails';
import DetailsTable from 'features/detailsTable';
import DurationDetails from '../features/durationDetails';
import VideoDetails from '../features/videoDetails';

// Components
import OverlaySpinner from './OverlaySpinner';

// redux
import {
  overviewSelector,
  customEventsSelector,
  urlSelector,
  stopDurationsSelector,
  uniquesSelector,
  videoEventsSelector
} from './selectors';

import {
  selectAds,
  setCampaignUUID,
  clearError,
  setQuery,
  setShowUniques,
  setCampaignData,
  setCampaignCombinedData,
  setAppGrouping
} from './campaignSlice';

import { useCampaign, useCampaignData } from './useCampaign';

// css
import './App.css';

function App() {
  const {
    details,
    error,
    uuid: campaignUUID
  } = useSelector(state => state.campaign);
  const dispatch = useDispatch();

  const overviewData = useSelector(overviewSelector);
  const eventsData = useSelector(customEventsSelector);
  const urlData = useSelector(urlSelector);
  const stopDurationsData = useSelector(stopDurationsSelector);
  const uniques = useSelector(uniquesSelector);
  const videoData = useSelector(videoEventsSelector);

  useEffect(() => {
    const locationHandler = evt => {
      const { uuid, query, params } = parseHash();
      const { richie_show_uniques, app_grouping } = params;
      batch(() => {
        dispatch(setCampaignUUID(uuid));
        dispatch(setQuery(query));
        dispatch(
          setShowUniques(parseInt(richie_show_uniques, 10) === 1 ? true : false)
        );
        dispatch(
          setAppGrouping(parseInt(app_grouping, 10) === 1 ? true : false)
        );
        dispatch(setCampaignData({}));
        dispatch(setCampaignCombinedData({}));
      });
    };

    window.addEventListener('hashchange', locationHandler, false);

    locationHandler(); // update now

    // return cleanup function
    return () => {
      window.removeEventListener('hashchange', locationHandler, false);
    };
  }, [dispatch]); // only on initial "mount"

  // Fetch data

  const { isValidating: campaignLoading } = useCampaign();

  const { isValidating: campaignDataLoading } = useCampaignData(false);

  const { isValidating: campaignCombinedDataLoading } = useCampaignData(true);

  const onAdSelect = useCallback(
    selected => {
      dispatch(selectAds(selected));
    },
    [dispatch]
  );

  const pageTitle = details && details.name ? details.name : 'Richie Campaign';

  return (
    <div className="position-relative">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <OverlaySpinner
        show={
          campaignUUID &&
          (campaignLoading ||
            campaignDataLoading ||
            campaignCombinedDataLoading)
        }
      ></OverlaySpinner>
      {details && (
        <>
          <Header
            title={details.name}
            dates={{ start: details.start, end: details.end }}
          />
          <DataFilter key={campaignUUID} />
        </>
      )}
      <Container className="mt-2">
        {details && (
          <div>
            <Overview
              data={overviewData}
              uniquesData={uniques}
              loading={campaignCombinedDataLoading}
            />
            <AdPicker onSelect={onAdSelect} loading={campaignDataLoading} />
            <ImpressionChart loading={campaignDataLoading} />
            {videoData && videoData.started > 0 && (
              <VideoDetails
                data={videoData}
                loading={campaignCombinedDataLoading}
              />
            )}
            <DurationDetails
              data={stopDurationsData}
              loading={campaignCombinedDataLoading}
            />
            {urlData.length > 0 && (
              <ClickDetails
                data={urlData}
                loading={campaignCombinedDataLoading}
              />
            )}
            {eventsData.length > 0 && (
              <CustomDetails
                data={eventsData}
                loading={campaignCombinedDataLoading}
              />
            )}
            <DetailsTable loading={campaignDataLoading} />
          </div>
        )}
      </Container>
      <Toast
        show={!!error}
        onClose={() => {
          dispatch(clearError());
        }}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          minWidth: '300px'
        }}
      >
        <Toast.Header className="bg-danger text-white">
          <FaBell className="mr-2" />
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body className="bg-light">{error}</Toast.Body>
      </Toast>
    </div>
  );
}

export default App;
