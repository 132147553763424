import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'react-bootstrap';

import PieChart from 'common/PieChart';

import SectionCard from 'common/SectionCard';

import Duration from './Duration';

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
  verbose: PropTypes.bool
};

const DurationDetails = props => {
  const { data = {}, loading = false } = props;
  const { stopping = [], durations = {}, stoppingPercentage } = data;

  const helpText =
    "Visitors are considered having stopped on the ad if they've spent at least 5 seconds on it at least once during the campaign period. For such users, their mean (average) total dwell time over the whole campaign period is provided, as well as separate values for the top 10% and top 30% of visitors by total dwell time.";

  return (
    <SectionCard title="Dwell time" help={helpText} loading={loading}>
      <Row>
        <Col md={12} lg={6}>
          <h5>Stopping visitors</h5>
          <hr />
          <PieChart data={stopping} x="type" y="value" scale={0.6} />
        </Col>
        <Col>
          <h5>Total campaign dwell time per stopping visitor</h5>
          <hr />
          {durations && 'mean' in durations && (
            <Table className="mt-4" borderless hover size="sm">
              <tbody>
                <tr>
                  <td>
                    <strong>Top 10% of visitors</strong>
                  </td>
                  <td>
                    <Duration duration={durations.top10} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Top 30% of visitors</strong>
                  </td>
                  <td>
                    <Duration duration={durations.top30} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>All stopping visitors</strong>
                  </td>
                  <td>
                    <Duration duration={durations.mean} />
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {stoppingPercentage && (
            <div className="my-4 text-muted">
              <small>
                During the campaign period, {stoppingPercentage.toFixed(1)}% of
                visitors stopped on the ad page at least once, for at least 5
                seconds. Out of these stopping visitors, the average (mean) time
                spent with the ad was{' '}
                <Duration duration={durations.top10} verbose /> for the top 10%
                of stopping visitors,{' '}
                <Duration duration={durations.top30} verbose /> for the top 30%,
                and <Duration duration={durations.mean} verbose /> for all
                stopping visitors.
              </small>
            </div>
          )}
        </Col>
      </Row>
    </SectionCard>
  );
};

DurationDetails.propTypes = {
  data: PropTypes.shape({
    stopping: PropTypes.array,
    durations: PropTypes.shape({
      mean: PropTypes.number,
      top10: PropTypes.number,
      top30: PropTypes.number
    }),
    stoppingPercentage: PropTypes.number
  }).isRequired,
  loading: PropTypes.bool
};

export default DurationDetails;
