import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';

CSVExport.propTypes = {
  getData: PropTypes.func.isRequired,
  filename: PropTypes.string
};

const handleCSVExport = async (getData, filename = 'export.csv') => {
  if (typeof getData !== 'function') {
    throw new Error('getData is required');
  }
  const data = getData();
  let csvContent = unparse(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, filename);
};

function CSVExport(props) {
  const { getData, filename } = props;
  return (
    <Button
      variant="outline-dark"
      className="mb-2 float-right"
      size="sm"
      onClick={() => handleCSVExport(getData, filename)}
    >
      Export CSV
    </Button>
  );
}

export default CSVExport;
