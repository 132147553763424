import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: true,
  selectedAds: {}
};

const adPickerSlice = createSlice({
  name: 'adPicker',
  initialState,
  reducers: {
    toggleAd: (state, action) => {
      const key = action.payload;
      state.selectedAds[key] = !state.selectedAds[key];
    },
    selectAds: (state, action) => {
      const ads = action.payload;
      const selectedAds = ads.reduce((selected, ad) => {
        selected[ad.uuid] = true;
        return selected;
      }, {});
      state.selectedAds = selectedAds;
    },
    toggleOpen: state => {
      state.isOpen = !state.isOpen;
    }
  }
});

export const { toggleAd, selectAds, toggleOpen } = adPickerSlice.actions;

export default adPickerSlice.reducer;
