import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme
} from 'victory';

const EventsChart = ({ data }) => {
  return (
    <VictoryChart
      domainPadding={8}
      height={100}
      padding={{ left: 40, right: 20, bottom: 30}}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 6
          }
        }}
        tickCount={5}
        tickFormat={x => `${x}%`}
      />
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: {
            fontSize: 5
          }
        }}
      />
      <VictoryBar
        barRatio={0.9}
        colorScale={'qualitative'}
        alignment="middle"
        data={data}
        y="count"
        x="percentage"
        style={{
          labels: {
            fontSize: 5
          }
        }}
      />
    </VictoryChart>
  );
};

export default EventsChart;
