import { combineReducers } from '@reduxjs/toolkit';

import campaign from 'app/campaignSlice';
import dataFilter from 'features/dataFilter/dataFilterSlice';
import adPicker from 'features/adPicker/adPickerSlice';

const rootReducer = combineReducers({
  campaign,
  adPicker,
  dataFilter
});

export default rootReducer;
