import React from 'react';
import PropTypes from 'prop-types';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components';

import OverlaySpinner from 'app/OverlaySpinner';

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  help: PropTypes.string,
  height: PropTypes.string,
  toolbar: PropTypes.element
};

const TooltipContainer = styled.span`
  vertical-align: middle;
  font-size: 12px;
`;

function SectionCard(props) {
  const {
    title,
    children,
    help,
    height = 'auto',
    toolbar,
    loading,
    noheader,
    subtitle,
    onClick
  } = props;
  const header = (
    <div className="d-flex align-items-center justify-content-between">
      <h5 className="p-0 m-0 flex-grow-1">
        {title}
        &nbsp;
        {subtitle && <small className="text-muted">{subtitle}</small>}
        &nbsp;
        {help && help.length > 0 && (
          <TooltipContainer>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{help}</Tooltip>}
            >
              <FaQuestionCircle />
            </OverlayTrigger>
          </TooltipContainer>
        )}
      </h5>
      {toolbar}
    </div>
  );

  return (
    <Card className="my-4" style={{ height: height }}>
      <OverlaySpinner show={loading} container={true} />
      {!noheader && (
        <Card.Header
          style={{ cursor: onClick ? 'pointer' : 'inherit' }}
          onClick={() => {
            onClick && onClick();
          }}
        >
          {header}
        </Card.Header>
      )}
      <Card.Body>
        {noheader && <Card.Title>{header}</Card.Title>}
        {children}
      </Card.Body>
    </Card>
  );
}

export default SectionCard;
