import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSWR from 'swr';
import moment from 'moment';
import {
  setError,
  setCampaign,
  setCampaignData,
  setCampaignCombinedData
} from './campaignSlice';
import * as campaignService from '../utils/campaignService';

const swrOptions = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
};

export function useCampaign() {
  const {
    query,
    uuid: campaignUUID,
    error
  } = useSelector(state => state.campaign);

  const dispatch = useDispatch();

  const {
    data
  } = useSWR(campaignUUID ? [campaignUUID, query] : null, campaignService.fetchCampaign, {
    ...swrOptions,
    onError: (err) => {
      dispatch(setError(err.message));
    },
    onSuccess: (data) => {
      dispatch(setCampaign(data));
    }
  });
  return {
    isValidating: !data && !error
  }
}

export function useCampaignData(combined) {
  const {
    campaignLoaded,
    selectedAds,
    query,
    uuid: campaignUUID,
    appGrouping,
    details,
    error
  } = useSelector(state => state.campaign);
  const dataFilter = useSelector(state => state.dataFilter);
  const dispatch = useDispatch();
  const skipLoading = !campaignLoaded || !selectedAds || selectedAds.length === 0;

  const isRunningAd = React.useMemo(() => {
    if (!details) {
      return false;
    }
    return moment().isBetween(moment(details.start), moment(details.end));
  }, [details]);

  const fetchFilter = React.useMemo(() => {
    const apps = dataFilter.application ? [dataFilter.application] : null;
    return {
      ...dataFilter,
      ads: selectedAds,
      apps: apps
    };
  }, [dataFilter, selectedAds]);

  const options = React.useMemo(() => {
    return {
      combined,
      appGrouping
    }
  }, [combined, appGrouping]);

  const {
    data,
  } = useSWR(!skipLoading ? [campaignUUID, fetchFilter, query, options] : null, campaignService.fetchAnalyticsData, {
    ...swrOptions,
    revalidateOnFocus: isRunningAd,
    onError: (err) => {
      dispatch(setError(err.message));
    },
    onSuccess: (data) => {
      if (!combined) {
        dispatch(setCampaignData(data));
      } else {
        dispatch(setCampaignCombinedData(data));
      }
    }
  });

  return {
    isValidating: !data && !error
  }
}