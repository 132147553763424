import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryGroup,
  VictoryLegend,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory';

import SectionCard from 'common/SectionCard';
import TimeResolutionPicker from 'common/TimeResolutionPicker';

import {
  impressionChartSelector,
  availableTimeResolutionsSelector
} from 'app/selectors';

import { getFirstAvailableResolution } from 'utils/utils';

const ImpressionChart = ({loading}) => {
  const [timeResolution, setTimeResolution] = useState('days');
  const availableTimeResolutions = useSelector(
    availableTimeResolutionsSelector
  );

  useEffect(() => {
    const resolution = getFirstAvailableResolution(
      timeResolution,
      availableTimeResolutions
    );
    if (resolution !== timeResolution) {
      setTimeResolution(resolution);
    }
  }, [timeResolution, availableTimeResolutions]);

  const data = useSelector(state =>
    impressionChartSelector(state, timeResolution)
  );

  const handleResolutionChange = useCallback(resolution => {
    setTimeResolution(resolution);
  }, []);

  const toolbar = (
    <TimeResolutionPicker
      value={timeResolution}
      resolutions={availableTimeResolutions}
      onChange={handleResolutionChange}
    />
  );

  const labelComponent = (
    <VictoryTooltip
      pointerOrientation="bottom"
      pointerWidth={10}
      cornerRadius={3}
      style={{ fontSize: 9 }}
      constrainToVisibleArea
      flyoutHeight={55}
      labelComponent={
        <VictoryLabel
          dx={-45}
          text={d => [
            `${moment(d.datum.date).format(
              timeResolution === 'hours' ? 'lll' : 'll'
            )}`,
            `Total impressions: ${d.datum.impressions}`,
            `New impressions: ${d.datum.newImpressions}`,
            `Interactions: ${d.datum.interactions}`
          ]}
          textAnchor="start"
          lineHeight={1.2}
        />
      }
    />
  );

  const lineStyle = {
    data: {
      strokeWidth: timeResolution === 'hours' ? 1 : 2
    }
  };

  return (
    <SectionCard title="Impressions and interactions" toolbar={toolbar} loading={loading}>
      <VictoryChart
        width={600}
        height={200}
        domainPadding={{ y: 5 }}
        padding={{ top: 40, bottom: 40, left: 60, right: 20 }}
        theme={VictoryTheme.material}
        scale={{ x: 'time', y: 'linear' }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={d => `Total impressions: ${d.datum.impressions}`}
            labelComponent={labelComponent}
          />
        }
      >
        <VictoryLegend
          x={300}
          y={10}
          colorScale={'qualitative'}
          orientation="horizontal"
          gutter={10}
          style={{ labels: { fontSize: 9 }, parent: { fill: '#aaa' } }}
          data={[
            { name: 'Impressions' },
            { name: 'New impressions' },
            { name: 'Interactions' }
          ]}
        />

        <VictoryAxis
          style={{ tickLabels: { fontSize: 9 }, grid: { stroke: null } }}
        />
        <VictoryAxis
          dependentAxis
          style={{ tickLabels: { fontSize: 9 } }}
          domain={[0, 50]}
        />
        <VictoryGroup colorScale={'qualitative'} style={{ data: { width: 0 }}}>
          <VictoryLine data={data} x="date" y="impressions" style={lineStyle} />
          <VictoryLine
            data={data}
            x="date"
            y="newImpressions"
            style={lineStyle}
          />
          <VictoryLine
            data={data}
            x="date"
            y="interactions"
            style={lineStyle}
          />
        </VictoryGroup>
      </VictoryChart>
    </SectionCard>
  );
};

export default ImpressionChart;
