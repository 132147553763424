import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import Moment from 'moment';

DatePicker.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Moment)),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Moment).isRequired,
  maxDate: PropTypes.instanceOf(Moment).isRequired
};

function DatePicker(props) {
  const { dateRange, onChange, minDate, maxDate } = props;
  const [focusedDatepicker, setFocusedDatepicker] = useState(null);
  const [currentDateRange, setCurrentDateRange] = useState(dateRange);

  const handleDateRangeChange = useCallback(
    ({ startDate, endDate }) => {
      // dispatch selected range to redux store
      // this triggers update
      if (startDate && endDate) {
        if (
          startDate.isSame(dateRange[0], 'day') &&
          endDate.isSame(dateRange[1], 'day')
        ) {
          return; // no changes to current selection
        }
        const start = startDate.startOf('day').toISOString();
        const end = endDate.endOf('day').toISOString();
        onChange([start, end]);
      } else {
        if (!dateRange[0] && !dateRange[1]) {
          return; // no changes to current selection
        }
        onChange([]);
      }
    },
    [onChange, dateRange]
  );

  const isOutsideRange = useCallback(
    day => {
      return !day.isBetween(
        minDate.startOf('day'),
        maxDate.endOf('day'),
        'hours',
        '[)'
      );
    },
    [minDate, maxDate]
  );

  return (
    <DateRangePicker
      startDate={currentDateRange[0]} // momentPropTypes.momentObj or null,
      startDateId="start" // PropTypes.string.isRequired,
      small
      showClearDates
      endDate={currentDateRange[1]} // momentPropTypes.momentObj or null,
      endDateId="end" // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => {
        setCurrentDateRange([startDate, endDate]);
        if (!startDate && !endDate) {
          // clearing dates doesn't involve picker open, so dispatch change immediately
          handleDateRangeChange({ startDate, endDate });
        }
      }} // PropTypes.func.isRequired,
      focusedInput={focusedDatepicker} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={focusedInput => setFocusedDatepicker(focusedInput)} // PropTypes.func.isRequired,
      isOutsideRange={day => isOutsideRange(day)}
      initialVisibleMonth={() => minDate}
      onClose={handleDateRangeChange}
      minimumNights={0}
      numberOfMonths={minDate.get('month') !== maxDate.get('month') ? 2 : 1}
      startDatePlaceholderText="From"
      endDatePlaceholderText="To"
      hideKeyboardShortcutsPanel={true}
    />
  );
}

export default DatePicker;
