import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  uuid: null,
  query: '',
  showUniques: false,
  pending: false,
  dataPending: false,
  details: null,
  ads: null,
  apps: {},
  data: {},
  combinedData: {
    pending: false,
    data: null
  },
  selectedAds: [],
  errors: [],
  campaignLoaded: false,
  appGrouping: false,
};

const campaign = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignUUID: (state, action) => {
      // reset to initial state when changing uuid
      if (action.payload !== state.uuid) {
        return { ...initialState, uuid: action.payload };
      }
      return state;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setShowUniques: (state, action) => {
      state.showUniques = action.payload;
    },
    selectAds: (state, action) => {
      const selected = action.payload;
      state.selectedAds = selected;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state, action) => {
      state.error = null;
    },
    setCampaign: (state, action) => {
      const { campaign, ads, apps } = action.payload;
      state.details = campaign;
      state.ads = ads;
      state.apps = apps;
      state.selectedAds = [];
      state.data = {};
      state.campaignLoaded = true;
    },
    setCampaignData: (state, action) => {
      state.data = action.payload;
    },
    setCampaignCombinedData: (state, action) => {
      state.combinedData.data = action.payload;
    },
    setAppGrouping: (state, action) => {
      state.appGrouping = action.payload;
    }
  }
});

export const {
  setCampaignUUID,
  setQuery,
  selectAds,
  setError,
  clearError,
  setShowUniques,
  setCampaign,
  setCampaignData,
  setCampaignCombinedData,
  setAppGrouping
} = campaign.actions;

export default campaign.reducer;
